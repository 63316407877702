<template>
  <v-card v-if="article">
    <v-card-title class="pt-10 pt-md-3">
      <v-icon class="mr-1">
        mdi-clipboard-text
      </v-icon>
      게시글 수정
      <v-checkbox
        v-if="$store.state.user.user.userAuth === 'M' || $store.state.user.user.userAuth === 'A'"
        v-model="thisArticle.notice"
        label="공지"
        class="ml-3"
      />
    </v-card-title>
    <v-card-text>
      <v-row>
        <v-col cols="12">
          <v-autocomplete
            v-if="companies && companies.length > 0 && !article.notice"
            v-model="thisArticle.company.idx"
            :items="companies"
            :search-input.sync="companyKeyword"
            prepend-inner-icon="mdi-store-search"
            dense
            label="업체명"
            placeholder="업체 검색"
            no-data-text="검색된 업체가 없습니다."
            class="pt-5"
          />
          <v-sheet v-if="!article.notice">
            <v-menu
              ref="datePickerMenu"
              v-model="showDatePicker"
              :close-on-content-click="true"
              :return-value="date"
              transition="scale-transition"
              offset-y
              min-width="auto"
            >
              <template #activator="{ attrs, on }">
                <v-text-field
                  v-model="date"
                  label="구매 날짜"
                  disabled
                  prepend-icon="mdi-calendar"
                  readonly
                  v-bind="attrs"
                  v-on="on"
                />
              </template>
              <v-date-picker
                v-model="date"
                locale="ko"
                no-title
                scrollable
              />
            </v-menu>
          </v-sheet>
          <v-sheet>
            <v-text-field
              v-model="thisArticle.title"
              label="Title"
            />
          </v-sheet>
          <v-sheet>
            <Editor ref="editor" :content="thisArticle.content" />
          </v-sheet>
        </v-col>
      </v-row>
    </v-card-text>
    <v-card-actions class="flex-wrap pt-15 mt-3 mt-md-0 px-5 pt-md-10">
      <v-btn
        class="mb-2"
        large
        width="100%"
        @click="saveArticle"
      >
        수정
      </v-btn>
      <v-btn
        class="mx-0"
        large
        width="100%"
        @click="close"
      >
        창 닫기
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
  import Editor from '@/components/core/Editor'
  export default {
    name: 'ArticleEdit',
    components: { Editor },
    props: [
      'article',
      'companies',
    ],
    data () {
      return {
        types: [
          {
            text: '구매',
            value: 'buy',
          },
          {
            text: '배송',
            value: 'delivery',
          },
          {
            text: '리뷰',
            value: 'review',
          },
        ],
        showDatePicker: false,
        date: this.article ? this.$moment(this.article.purchaseDate).format('YYYY-MM-DD') : '',
        company: null,
        companyKeyword: null,
      }
    },
    computed: {
      thisArticle () {
        return this.article
      },
    },
    methods: {
      close () {
        this.$emit('close-dialog')
      },
      async saveArticle () {
        let url = `/article/${this.thisArticle.idx}`
        if (this.$store.state.user.user.userAuth === 'M' || this.$store.state.user.user.userAuth === 'A') {
          url = `/manage/article/${this.thisArticle.idx}`
        }
        this.thisArticle.content = this.$refs.editor.getContent()
        this.thisArticle.purchaseDate = this.date ? Date.parse(this.date) : new Date()
        await this.$axios.patch(url, this.thisArticle).then(res => {
          if (res && res.data && res.data.status === 'SUCCESS') {
            alert('게시글을 수정하였습니다.')
            this.close()
          } else {
            alert(res.data.message ? res.data.message : '오류가 발생하였습니다.')
          }
        }).catch(e => {
          alert('오류가 발생하였습니다.')
        })
      },
    },
  }
</script>

<style scoped>

</style>
