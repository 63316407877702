<template>
  <v-card v-if="article">
    <v-card-title class="pt-10 pt-md-3">
      <v-icon class="mr-1">
        mdi-alpha-p-box
      </v-icon>
      포인트 처리
    </v-card-title>
    <v-card-text>
      <v-row>
        <v-col cols="12">
          <v-sheet class="d-flex">
            <v-checkbox v-model="status" value="confirm" label="승인" class="mr-3" />
            <v-checkbox v-model="status" value="reject" label="거절" />
          </v-sheet>
          <v-sheet class="d-flex">
            <v-checkbox v-model="historyType" value="activity" label="활동" class="mr-3" />
            <v-checkbox v-model="historyType" value="recommend" label="추천" class="mr-3" />
            <v-checkbox v-model="historyType" value="fee" label="적립" />
          </v-sheet>
          <v-sheet>
            <v-text-field
              v-model="amount"
              label="적립할 포인트"
              type="number"
              hide-spin-buttons
              @keyup="enterEvent"
            />
            <v-text-field
              v-model="content"
              label="포인트 적립/차감 내용"
              @keyup="enterEvent"
            />
          </v-sheet>
        </v-col>
      </v-row>
    </v-card-text>
    <v-card-actions class="flex-wrap">
      <v-btn
        width="100%"
        large
        class="mb-2"
        @click="processPoint"
      >
        처리
      </v-btn>
      <v-btn
        width="100%"
        large
        class="mx-0"
        @click="close"
      >
        창 닫기
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
  export default {
    name: 'ProcessPoint',
    props: [
      'article',
    ],
    data () {
      return {
        status: 'confirm',
        historyType: this.article.articleType === 'delivery' || this.article.articleType === 'pending' || this.article.articleType === 'buy' ? 'activity' : this.article.articleType === 'review' ? 'fee' : '',
        content: null,
        amount: null,
        isProcessing: false,
      }
    },
    beforeMount () {
      this.getAmount()
    },
    methods: {
      getAmount () {
        if (this.article.articleType === 'pending' || this.article.articleType === 'buy') {
          this.amount = this.$store.state.site.site.buyFixedPoint != null ? this.amount = this.$store.state.site.site.buyFixedPoint : null
        } else if (this.article.articleType === 'delivery') {
          this.amount = this.$store.state.site.site.deliveryFixedPoint != null ? this.$store.state.site.site.deliveryFixedPoint : null
        } else if (this.article.articleType === 'review') {
          this.amount = this.article.productRegistration ? this.article.productRegistration.options.optionPrice : this.article.company.companyFixedPoint != null ? this.article.company.companyFixedPoint : null
        }
      },
      close () {
        this.$emit('close-dialog')
      },
      async processPoint () {
        const url = '/manage/article/process/point'
        if (this.isProcessing) {
          alert('현재 포인트 처리가 진행중입니다.')
        }
        if (!this.status) {
          alert('승인 / 거절 중 하나를 선택해주세요.')
          return
        }
        if (!this.historyType) {
          alert('활동/추천/적립 중 하나를 선택해주세요.')
          return
        }
        this.isProcessing = true
        const data = {
          content: this.content,
          status: this.status,
          historyType: this.historyType,
          amount: this.amount,
          article: this.article,
          additionalPoint: false,
        }
        await this.$axios.post(url, data).then(res => {
          if (res && res.data && res.data.status === 'SUCCESS') {
            alert('포인트 처리가 완료되었습니다.')
            this.close()
          } else {
            alert(res.data.message ? res.data.message : '오류가 발생하였습니다.')
          }
        }).catch(e => {
          alert('오류가 발생하였습니다.')
        }).finally(() => {
          this.isProcessing = false
        })
      },
      enterEvent () {
        if (event.keyCode === 13) {
          this.processPoint()
        }
      },
    },
  }
</script>

<style scoped>

</style>
