<template>
  <v-lazy
    :options="{
      threshold: .5
    }"
  >
    <v-container>
      <BoardViewHeader />
      <BoardViewContent
        :article="article"
        :popup="false"
        :companies="companies"
        :product="product"
        :options="options"
        @refresh="getArticle"
      />
    </v-container>
  </v-lazy>
</template>

<script>
  import BoardViewHeader from '@/views/board/view/Header'
  import BoardViewContent from '@/views/board/view/Content'
  export default {
    name: 'BoardView',
    components: { BoardViewContent, BoardViewHeader },
    data () {
      return {
        article: {},
        companies: [],
      }
    },
    computed: {
      product () {
        if (this.article && this.article.productRegistration && this.article.productRegistration.product) {
          return this.article && this.article.productRegistration && this.article.productRegistration.product
        } else {
          return {}
        }
      },
      options () {
        if (this.article && this.article.productRegistration && this.article.productRegistration.options) {
          return this.article && this.article.productRegistration && this.article.productRegistration.options
        } else {
          return {}
        }
      },
      articleIdx () {
        return this.$route.params.articleIdx
      },
    },
    beforeMount () {
      this.getArticle()
      this.getCompanies()
    },
    methods: {
      async getCompanies () {
        const url = '/company/company/items'
        await this.$axios.get(url)
          .then(res => {
            if (res && res.data) {
              this.companies = res.data
            }
          })
          .catch(err => {
            console.log(err)
          })
      },
      getArticle () {
        const url = `/article/${this.articleIdx}`
        this.$axios.get(url)
          .then(res => {
            if (res && res.data) {
              this.article = res.data
            }
          })
          .catch(err => {
            console.log(err)
          })
      },
    },
  }
</script>

<style scoped>

</style>
