<template>
  <v-row v-if="article">
    <v-col cols="12">
      <v-card>
        <v-card-actions v-if="!popup && user.admin">
          <v-dialog
            v-if="($store.state.user.user.userAuth === 'M' || $store.state.user.user.userAuth === 'A') && !thisArticle.notice"
            v-model="showUpsideProcessPoint"
            persistent
            width="400px"
            :fullscreen="mobile"
            :transition="mobile ? 'dialog-bottom-transition' : 'scale-transition'"
          >
            <template #activator="{ attrs, on }">
              <v-btn
                v-if="!(thisArticle.status === 'confirm' && thisArticle.articleType === 'review')"
                large
                v-bind="attrs"
                v-on="on"
              >
                포인트
              </v-btn>
            </template>
            <ProcessPoint v-if="showUpsideProcessPoint" :article="article" @close-dialog="closePointProcess" />
          </v-dialog>
        </v-card-actions>
        <v-card-title>
          <v-icon class="mr-2">
            mdi-clipboard-text
          </v-icon>
          <h4 class="text-truncate">
            {{ product.month ? '[한달리뷰]' : '' }}{{ thisArticle.title }}
          </h4>
        </v-card-title>
        <v-card-subtitle class="d-flex">
          <v-chip
            v-if="thisArticle.company"
            label
            class="mt-3 mr-2"
          >
            <v-icon class="text-subtitle-1 mr-1">
              mdi-domain
            </v-icon>
            <span class="text-subtitle-1">
              {{ thisArticle.company.companyName }}
            </span>
          </v-chip>
          <v-chip
            v-if="thisArticle.productRegistration && thisArticle.productRegistration.product"
            label
            class="mt-3 mr-2"
          >
            <v-icon class="text-subtitle-1 mr-1">
              mdi-archive
            </v-icon>
            <span class="text-subtitle-1">
              {{ thisArticle.productRegistration.product.productName }}
            </span>
          </v-chip>
          <v-chip
            label
            class="mt-3 mr-2"
          >
            <v-icon class="text-subtitle-1 mr-1">
              mdi-account
            </v-icon>
            <span class="text-subtitle-1">
              {{ thisArticle.notice ? '관리자' : author }}
            </span>
          </v-chip>
          <v-chip
            label
            class="mt-3"
          >
            <v-icon class="text-subtitle-1 mr-1">
              mdi-phone
            </v-icon>
            <span class="text-subtitle-1">
              {{ thisArticle && thisArticle.alias ? thisArticle.alias.mobileIdx : thisArticle.users ? thisArticle.users.mobileIdx : '' }}
            </span>
          </v-chip>
          <v-spacer />
          <v-chip label class="mt-3">
            <v-icon class="text-subtitle-1 mr-1">
              mdi-clock-time-four-outline
            </v-icon>
            <span v-if="new Date().getTime() - thisArticle.registerDate < 1000 * 60 * 60 * 7">
              {{ thisArticle.registerDate | moment('from', 'now') }}
            </span>
            <span v-else>
              {{ thisArticle.registerDate | moment('YYYY.MM.DD. HH:mm:ss') }}
            </span>
          </v-chip>
        </v-card-subtitle>
        <v-card-subtitle v-if="!thisArticle.notice">
          <v-stepper color="transparent" class="mb-5 stepper">
            <v-stepper-header>
              <v-stepper-step
                color="dark"
                complete-icon="mdi-check"
                :complete="(thisArticle.articleType === 'pending' && thisArticle.status === 'confirm') || thisArticle.articleType === 'buy' || thisArticle.articleType === 'delivery' || thisArticle.articleType === 'review'"
                step="대기"
              >
                대기
              </v-stepper-step>
              <v-divider />
              <v-stepper-step
                color="dark"
                :complete-icon="thisArticle.articleType === 'buy' && thisArticle.status === 'reject' ? 'mdi-cancel' : 'mdi-check'"
                :complete="(thisArticle.articleType === 'buy' && thisArticle.status !== 'pending' && thisArticle.status !== 'blank') || thisArticle.articleType === 'delivery' || thisArticle.articleType === 'review'"
                step="구매"
              >
                상품구매 업로드
              </v-stepper-step>
              <v-divider />
              <v-stepper-step
                color="dark"
                :complete-icon="thisArticle.articleType === 'delivery' && thisArticle.status === 'reject' ? 'mdi-cancel' : 'mdi-check'"
                :complete="(thisArticle.articleType === 'delivery' && thisArticle.status !== 'pending' && thisArticle.status !== 'blank') || thisArticle.articleType === 'review' "
                :step="product.month ? '구매확정' : '배송'"
              >
                <template v-if="product.month">
                  구매확정 업로드
                </template>
                <template v-else>
                  배송완료 업로드
                </template>
              </v-stepper-step>
              <v-divider />
              <v-stepper-step
                color="dark"
                :complete-icon="thisArticle.articleType === 'review' && thisArticle.status === 'reject' ? 'mdi-cancel' : 'mdi-check'"
                :complete="thisArticle.articleType === 'review' && thisArticle.status !== 'pending' && thisArticle.status !== 'blank'"
                step="리뷰"
              >
                리뷰작성 업로드
              </v-stepper-step>
            </v-stepper-header>
          </v-stepper>
          <v-text-field
            v-if="thisArticle.notice"
            disabled
            label="게시글 타입"
            value="공지사항"
          />
          <v-text-field
            v-if="!thisArticle.notice"
            disabled
            label="구매자"
            :value="thisArticle.purchaseName"
          />
          <v-text-field
            v-if="!thisArticle.notice"
            disabled
            label="구매일"
            :value="article.productRegistration ? parseDate(article.productRegistration.purchaseDate) : parseDate(thisArticle.purchaseDate)"
          />
          <v-text-field
            v-if="article.productRegistration"
            disabled
            label="옵션"
            :value="article.productRegistration.options && article.productRegistration.options.optionContent && article.productRegistration.options.optionType ? '[' + article.productRegistration.options.optionType.optionTypeName + '] ' + article.productRegistration.options.optionContent : '삭제된 옵션'"
          />
          <v-text-field
            v-if="user.admin && article.productRegistration"
            disabled
            label="구매금액"
            hide-details
            :value="article.confirmFeePoints ? (article.confirmFeePoints.toLocaleString() + ' (' + (article.productRegistration.options && article.productRegistration.options.optionPrice ? article.productRegistration.options.optionPrice.toLocaleString() : '') + ')') : article.productRegistration.options && article.productRegistration.options.optionPrice ? article.productRegistration.options.optionPrice.toLocaleString() : '정보없음'"
          />
          <v-text-field
            v-if="thisArticle.notice"
            disabled
            label="작성일"
            :value="parseDateTime(thisArticle.registerDate)"
          />
          <v-file-input
            v-if="!(thisArticle.articleType === 'review' && thisArticle.status === 'confirm')"
            v-model="image"
            accept="image/*"
            :label="imagePlaceHolder"
            prepend-icon="mdi-camera"
            @change="uploadImage"
          />
        </v-card-subtitle>
        <v-card-text>
          <v-row>
            <v-col v-if="thisArticle.buyImage" cols="4" md="3">
              <p class="text-center mb-1">
                상품구매 캡쳐
              </p>
              <v-dialog
                v-if="!thisArticle.notice"
                v-model="showBuyImage"
                max-width="100%"
                width="100%"
                max-height="100%"
              >
                <template #activator="{ attrs, on }">
                  <v-img
                    :src="baseUrl + thisArticle.buyImage"
                    :lazy-src="require('@/assets/images/no-image.jpg')"
                    max-width="100%"
                    height="100px"
                    v-bind="attrs"
                    v-on="on"
                  >
                    <span
                      class="px-3 py-1 text-caption"
                      :style="{
                        position: 'absolute',
                        bottom: '5px',
                        right: '5px',
                        background: 'rgba(0, 0, 0, 0.5)',
                        borderRadius: '5px'
                      }"
                    >
                      {{ article.buyImageUploadDate ? $parseDateSecond(article.buyImageUploadDate) : '' }}
                    </span>
                  </v-img>
                </template>
                <v-card class="pa-0">
                  <v-card-text class="pa-1" :style="{ position: 'relative' }">
                    <v-btn
                      x-small
                      right
                      absolute
                      color="black"
                      :style="{ right: 0, top: 0, zIndex: 1000 }"
                      @click="showBuyImage = false"
                    >
                      <v-icon>
                        mdi-close
                      </v-icon>
                    </v-btn>
                    <v-img
                      :src="baseUrl + thisArticle.buyImage"
                      :lazy-src="require('@/assets/images/no-image.jpg')"
                      max-width="100%"
                      class="rounded-5"
                    >
                      <span
                        class="px-3 py-1 text-caption"
                        :style="{
                          position: 'absolute',
                          bottom: '5px',
                          right: '5px',
                          background: 'rgba(0, 0, 0, 0.5)',
                          borderRadius: '5px'
                        }"
                      >
                        {{ article.buyImageUploadDate ? $parseDateSecond(article.buyImageUploadDate) : '' }}
                      </span>
                    </v-img>
                  </v-card-text>
                </v-card>
              </v-dialog>
              <v-skeleton-loader
                v-else
                width="100%"
                height="100px"
                type="card-avatar"
              />
              <template v-if="article.productRegistration && user.admin && (article.articleType === 'pending' || article.articleType === 'buy')">
                <div class="d-flex">
                  <v-text-field
                    v-model="thisArticle.confirmFeePoints"
                    label="구매금액"
                    class="text-caption ml-1 w-50"
                    hide-details
                  />
                  <v-text-field
                    v-model="buyFixedPoint"
                    label="구매포인트"
                    class="text-caption m-1 w-50"
                    hide-details
                  />
                </div>
                <v-btn
                  width="100%"
                  :x-small="!$isMobile"
                  :small="$isMobile"
                  class="mt-1"
                  @click="processBuy"
                >
                  승인
                </v-btn>
              </template>
            </v-col>
            <v-col cols="4" md="3">
              <p class="text-center mb-1">
                <template v-if="product.month">
                  구매확정 캡쳐
                </template>
                <template v-else>
                  배송완료 캡쳐
                </template>
              </p>
              <v-dialog
                v-if="thisArticle.deliveryImage"
                v-model="showDeliveryImage"
                max-width="100%"
                width="90%"
              >
                <template #activator="{ attrs, on }">
                  <v-img
                    :src="baseUrl + thisArticle.deliveryImage"
                    max-width="100%"
                    height="100px"
                    :lazy-src="require('@/assets/images/no-image.jpg')"
                    v-bind="attrs"
                    v-on="on"
                  >
                    <span
                      class="px-3 py-1 text-caption"
                      :style="{
                        position: 'absolute',
                        bottom: '5px',
                        right: '5px',
                        background: 'rgba(0, 0, 0, 0.5)',
                        borderRadius: '5px'
                      }"
                    >
                      {{ article.deliveryImageUploadDate ? $parseDateSecond(article.deliveryImageUploadDate) : '' }}
                    </span>
                  </v-img>
                </template>
                <v-card class="pa-0">
                  <v-card-text class="pa-1" :style="{ position: 'relative' }">
                    <v-btn
                      x-small
                      right
                      absolute
                      color="black"
                      :style="{ right: 0, top: 0, zIndex: 1000 }"
                      @click="showDeliveryImage = false"
                    >
                      <v-icon>
                        mdi-close
                      </v-icon>
                    </v-btn>
                    <v-img
                      :src="baseUrl + thisArticle.deliveryImage"
                      :lazy-src="require('@/assets/images/no-image.jpg')"
                      max-width="100%"
                      class="rounded-5"
                    >
                      <span
                        class="px-3 py-1 text-caption"
                        :style="{
                          position: 'absolute',
                          bottom: '5px',
                          right: '5px',
                          background: 'rgba(0, 0, 0, 0.5)',
                          borderRadius: '5px'
                        }"
                      >
                        {{ article.deliveryImageUploadDate ? $parseDateSecond(article.deliveryImageUploadDate) : '' }}
                      </span>
                    </v-img>
                  </v-card-text>
                </v-card>
              </v-dialog>
              <v-skeleton-loader
                v-else
                width="100%"
                height="100px"
                type="card-avatar"
              />
              <template v-if="article.productRegistration && user.admin && article.articleType === 'delivery'">
                <div>
                  <v-radio-group v-model="deliveryConfirmMessageType">
                    <v-radio
                      :label="`글로 리뷰${product.month ? ' (한달)' : ''}`"
                      class="text-caption"
                      value="text"
                    />
                    <v-radio
                      v-if="productRegistration && productRegistration.product.photoReviewCount && article.photoArticleCount >= 0"
                      :label="`포토 리뷰 (${article.photoArticleCount + '/' + productRegistration.product.photoReviewCount})${product.month ? ' (한달)' : ''}`"
                      class="text-caption"
                      value="photo"
                    />
                    <v-radio
                      v-else
                      :label="`포토 리뷰${product.month ? ' (한달)' : ''}`"
                      class="text-caption"
                      value="photo"
                    />
                    <v-radio
                      label="리뷰 작성x"
                      class="text-caption"
                      value="noReview"
                    />
                    <span class="d-flex">
                      <v-radio
                        class="w-20"
                        value="custom"
                      />
                      <v-text-field
                        v-model="deliveryConfirmMessage"
                        dense
                        label="기타"
                        class="text-caption w-80"
                        hide-details
                        :style="{ marginTop: '-3px' }"
                      />
                    </span>
                  </v-radio-group>
                </div>
                <div v-if="article.productRegistration" class="d-flex">
                  <v-text-field
                    v-model="deliveryFixedPoint"
                    :label="`${product.month ? '구매확정 포인트' : '배송완료 포인트'}`"
                    class="text-caption w-50 ml-1"
                    dense
                    hide-details
                  />
                </div>
                <v-btn
                  width="100%"
                  :x-small="!$isMobile"
                  :small="$isMobile"
                  class="mt-1"
                  @click="processDelivery"
                >
                  승인
                </v-btn>
              </template>
              <template v-if="thisArticle.deliveryMemo">
                <v-chip small label :style="{ width: '100%' }" class="justify-center">
                  {{ thisArticle.deliveryMemo }}
                </v-chip>
              </template>
            </v-col>
            <v-col cols="4" md="3">
              <p class="text-center mb-1">
                <template v-if="product.month">
                  한달리뷰 캡쳐
                </template>
                <template v-else>
                  리뷰작성 캡쳐
                </template>
              </p>
              <v-dialog
                v-if="thisArticle.reviewImage"
                v-model="showReviewImage"
                max-width="100%"
                width="90%"
                max-height="100%"
              >
                <template #activator="{ attrs, on }">
                  <v-img
                    :src="baseUrl + thisArticle.reviewImage"
                    :lazy-src="require('@/assets/images/no-image.jpg')"
                    max-width="100%"
                    height="100px"
                    v-bind="attrs"
                    v-on="on"
                  >
                    <span
                      class="px-3 py-1 text-caption"
                      :style="{
                        position: 'absolute',
                        bottom: '5px',
                        right: '5px',
                        background: 'rgba(0, 0, 0, 0.5)',
                        borderRadius: '5px'
                      }"
                    >
                      {{ article.reviewImageUploadDate ? $parseDateSecond(article.reviewImageUploadDate) : '' }}
                    </span>
                  </v-img>
                </template>
                <v-card class="pa-0">
                  <v-card-text class="pa-1" :style="{ position: 'relative' }">
                    <v-btn
                      x-small
                      right
                      absolute
                      color="black"
                      :style="{ right: 0, top: 0, zIndex: 1000 }"
                      @click="showReviewImage = false"
                    >
                      <v-icon>
                        mdi-close
                      </v-icon>
                    </v-btn>
                    <v-img
                      :src="baseUrl + thisArticle.reviewImage"
                      :lazy-src="require('@/assets/images/no-image.jpg')"
                      max-width="100%"
                      class="rounded-5"
                    />
                    <span
                      class="px-3 py-1 text-caption"
                      :style="{
                        position: 'absolute',
                        bottom: '5px',
                        right: '5px',
                        background: 'rgba(0, 0, 0, 0.5)',
                        borderRadius: '5px'
                      }"
                    >
                      {{ article.reviewImageUploadDate ? $parseDateSecond(article.reviewImageUploadDate) : '' }}
                    </span>
                  </v-card-text>
                </v-card>
              </v-dialog>
              <v-skeleton-loader
                v-else
                width="100%"
                height="100px"
                type="card-avatar"
              />
              <template v-if="article.productRegistration && user.admin && article.articleType === 'review' && article.status !== 'confirm'">
                <div class="d-flex">
                  <v-text-field
                    v-model="reviewFixedPoint"
                    label="리뷰 포인트"
                    class="text-caption"
                    hide-details
                  />
                </div>
                <v-btn
                  width="100%"
                  :x-small="!$isMobile"
                  :small="$isMobile"
                  class="mt-1"
                  @click="processReview"
                >
                  승인
                </v-btn>
              </template>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-text
          id="article-content"
          class="mt-10"
          :style="{
            maxHeight: popup ? '400px' : 'initial',
            overflowY: 'auto'
          }"
          v-html="thisArticle.content"
        />
        <v-card-actions v-if="!popup">
          <v-dialog
            v-if="($store.state.user.user.userAuth === 'M' || $store.state.user.user.userAuth === 'A') && !thisArticle.notice"
            v-model="showProcessPoint"
            persistent
            width="400px"
            :fullscreen="mobile"
            :transition="mobile ? 'dialog-bottom-transition' : 'scale-transition'"
          >
            <template #activator="{ attrs, on }">
              <v-btn
                v-if="!(thisArticle.status === 'confirm' && thisArticle.articleType === 'review')"
                large
                v-bind="attrs"
                v-on="on"
              >
                포인트
              </v-btn>
            </template>
            <ProcessPoint v-if="showProcessPoint" :article="article" @close-dialog="closePointProcess" />
          </v-dialog>
          <v-spacer />
          <v-btn
            v-if="thisArticle.users ? ($store.state.user.user.idx === thisArticle.users.idx && (thisArticle.articleType === 'pending' || thisArticle.articleType === 'buy' && (thisArticle.status === 'blank' || thisArticle.status === 'pending'))) || $store.state.user.user.userAuth === 'M' || $store.state.user.user.userAuth === 'A' : false"
            large
            @click="deleteArticle"
          >
            삭제
          </v-btn>
          <v-dialog
            v-if="thisArticle.users ? ($store.state.user.user.idx === thisArticle.users.idx) || $store.state.user.user.userAuth === 'M' || $store.state.user.user.userAuth === 'A' : false"
            v-model="showEdit"
            width="800px"
            :fullscreen="mobile"
            :transition="mobile ? 'dialog-bottom-transition' : 'scale-transition'"
          >
            <template #activator="{ attrs, on }">
              <v-btn
                large
                v-bind="attrs"
                class="ml-2"
                v-on="on"
              >
                수정
              </v-btn>
            </template>
            <ArticleEdit :article="article" :companies="companies" @close-dialog="closeEdit" />
          </v-dialog>
          <v-btn
            large
            @click="$router.go(-1)"
          >
            뒤로가기
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
  import ProcessPoint from '@/views/board/view/ProcessPoint'
  import ArticleEdit from '@/views/board/view/Edit'
  import { mapState } from 'vuex'

  export default {
    name: 'BoardViewContent',
    components: { ArticleEdit, ProcessPoint },
    props: {
      article: {},
      params: {},
      companies: {},
      popup: {
        default: false,
      },
      product: {},
      options: {},
    },
    data () {
      return {
        showProcessPoint: false,
        showUpsideProcessPoint: false,
        showEdit: false,
        image: null,
        showBuyImage: false,
        showDeliveryImage: false,
        showReviewImage: false,
        buyFixedPoint: Number(this.$store.state.site.site.buyFixedPoint),
        deliveryFixedPoint: this.product.month ? this.options.optionPrice : Number(this.$store.state.site.site.deliveryFixedPoint),
        deliveryConfirmMessageType: null,
        deliveryConfirmMessage: null,
        reviewFixedPoint: 0,
      }
    },
    computed: {
      ...mapState('user', ['user']),
      baseUrl () {
        const url = this.api
        return url
      },
      productRegistration () {
        return this.article.productRegistration
      },
      company () {
        return this.article.company
      },
      thisArticle () {
        return this.article
      },
      mobile () {
        return this.$isMobile
      },
      imagePlaceHolder () {
        let msg
        if (this.article.articleType === 'pending' || this.article.articleType === 'buy') {
          msg = '상품구매에 대한 캡쳐를 업로드해주세요.'
        } else if (this.article.articleType === 'delivery') {
          if (this.product.month) {
            msg = '구매확정에 대한 캡쳐를 업로드해주세요.'
          } else {
            msg = '배송완료에 대한 캡쳐를 업로드해주세요.'
          }
        } else if (this.article.articleType === 'review') {
          if (this.product.month) {
            msg = '한달리뷰에 대한 캡쳐를 업로드해주세요.'
          } else {
            msg = '리뷰작성에 대한 캡쳐를 업로드해주세요.'
          }
        }
        return msg
      },
      author () {
        let author = '정보없음'
        if (this.article.users) {
          const userId = this.article.users.id
          const username = this.article.users.username
          const aliasId = this.article.alias ? this.article.alias.id : null
          const aliasUsername = this.article.alias ? this.article.alias.username : null
          if (aliasId && aliasUsername) {
            author = username + '(' + userId + ') > ' + aliasUsername + '(' + aliasId + ')'
          } else {
            author = username + '(' + userId + ')'
          }
        }
        return author
      },
    },
    watch: {
      productRegistration () {
        if (this.article.productRegistration) {
          if (this.article.productRegistration.product.photoReviewCount > 0) {
            if (this.article.photoArticleCount < this.article.productRegistration.product.photoReviewCount) {
              this.deliveryConfirmMessageType = 'photo'
            }
          }
        }
      },
      product () {
        if (this.product.month) {
          this.deliveryFixedPoint = this.options.optionPrice
        }
      },
      optionPrice () {
        if (this.product.month) {
          this.reviewFixedPoint = 1000
        } else {
          this.reviewFixedPoint = this.article.confirmFeePoints ? this.article.confirmFeePoints : this.optionPrice
        }
      },
      company () {
        if (this.product.month) {
          this.reviewFixedPoint = 1000
        } else {
          this.reviewFixedPoint = this.article.confirmFeePoints ? this.article.confirmFeePoints : this.company.companyFixedPoint
          if (this.article.productRegistration) {
            this.reviewFixedPoint = this.article.confirmFeePoints ? this.article.confirmFeePoints : this.article.productRegistration.options ? this.article.productRegistration.options.optionPrice : '정보없음'
          }
        }
      },
    },
    methods: {
      parseDate (date) {
        if (!isNaN(date)) {
          const d = new Date()
          d.setTime(date)
          return this.$moment(d).format('YYYY.MM.DD')
        } else {
          return date
        }
      },
      parseDateTime (date) {
        if (!isNaN(date)) {
          const d = new Date()
          d.setTime(date)
          return this.$moment(d).format('YYYY-MM-DD HH:mm')
        } else {
          return date
        }
      },
      closeEdit () {
        this.showEdit = false
      },
      // eslint-disable-next-line max-statements
      async processBuy () {
        if (confirm('승인 하시겠습니까?')) {
          const url = '/manage/article/process/point'
          const content = this.$parseArticleTitleDate(new Date(this.thisArticle.purchaseDate)) + ' ' + this.thisArticle.purchaseName + '님 ' + this.thisArticle.productRegistration.product.productName + ' 구매확인'
          const historyType = 'activity'
          const amount = this.buyFixedPoint
          const data = {
            content: content,
            status: 'confirm',
            historyType: historyType,
            amount: amount,
            article: this.thisArticle,
          }
          await this.$axios.post(url, data).then(res => {
            if (res && res.data && res.data.status === 'SUCCESS') {
              alert('포인트 처리가 완료되었습니다.')
              this.singleRefresh()
            } else {
              alert(res.data.message ? res.data.message : '오류가 발생하였습니다.')
            }
          }).catch(e => {
            alert('오류가 발생하였습니다.')
          })
        }
      },
      // eslint-disable-next-line max-statements
      async processDelivery () {
        if (confirm('승인 하시겠습니까?')) {
          const url = '/manage/article/process/point'
          let content = ''
          let historyType
          if (this.product.month) {
            content = `${this.thisArticle.purchaseName}님 ${this.$parseArticleTitleDate(new Date(this.thisArticle.purchaseDate))} ${this.thisArticle.productRegistration.product.productName}`
            if (this.deliveryConfirmMessageType === 'text') {
              content += ' 한달 글로 리뷰입니다. 미리 리뷰작성 시 패널티 있습니다.'
              this.thisArticle.deliveryMemo = `글로 리뷰 ${this.product.month ? ' (한달)' : ''}`
            } else if (this.deliveryConfirmMessageType === 'photo') {
              content += ' 한달 포토 리뷰입니다. 미리 리뷰작성 시 패널티 있습니다.'
              this.thisArticle.deliveryMemo = `포토 리뷰 ${this.product.month ? ' (한달)' : ''}`
            } else if (this.deliveryConfirmMessageType === 'noReview') {
              content += ' 리뷰작성 하지 않는 건이므로 구매확정 캡처 리뷰작성 단계에 올려주세요.'
              this.thisArticle.deliveryMemo = '리뷰 작성x'
            } else {
              content += this.deliveryConfirmMessage
              this.thisArticle.deliveryMemo = this.deliveryConfirmMessage
            }
            content += ''
            historyType = 'fee'
          } else {
            content = this.$parseArticleTitleDate(new Date(this.thisArticle.purchaseDate)) + ' ' + this.thisArticle.purchaseName + '님 ' + this.thisArticle.productRegistration.product.productName
            if (this.deliveryConfirmMessageType === 'text') {
              content += ' 글로 리뷰'
              this.thisArticle.deliveryMemo = '글로 리뷰'
            } else if (this.deliveryConfirmMessageType === 'photo') {
              content += ' 포토 리뷰'
              this.thisArticle.deliveryMemo = '포토 리뷰'
            } else if (this.deliveryConfirmMessageType === 'noReview') {
              content += ' 리뷰 작성x, 리뷰단계에 구매확정 캡처 한 번 더 올려주세요.'
              this.thisArticle.deliveryMemo = '리뷰 작성x'
            } else {
              content += this.deliveryConfirmMessage
              this.thisArticle.deliveryMemo = this.deliveryConfirmMessage
            }
            content += ''
            historyType = 'activity'
          }
          const amount = this.deliveryFixedPoint
          const data = {
            content: content,
            status: 'confirm',
            historyType: historyType,
            amount: amount,
            article: this.thisArticle,
          }
          await this.$axios.post(url, data).then(res => {
            if (res && res.data && res.data.status === 'SUCCESS') {
              alert('포인트 처리가 완료되었습니다.')
              this.singleRefresh()
            } else {
              alert(res.data.message ? res.data.message : '오류가 발생하였습니다.')
            }
          }).catch(e => {
            alert('오류가 발생하였습니다.')
          })
        }
      },
      async processReview () {
        if (confirm('승인 하시겠습니까?')) {
          const url = '/manage/article/process/point'
          let content = ''
          if (this.product.month) {
            content = this.$parseArticleTitleDate(new Date(this.thisArticle.purchaseDate)) +
              ' ' +
              this.thisArticle.purchaseName +
              '님 ' +
              this.thisArticle.productRegistration.product.productName +
              ' 알바비 ' +
              this.reviewFixedPoint.toLocaleString()
          } else {
            content = this.$parseArticleTitleDate(new Date(this.thisArticle.purchaseDate)) +
              ' ' +
              this.thisArticle.purchaseName +
              '님 ' +
              this.thisArticle.productRegistration.product.productName +
              ' 제품비 ' +
              this.reviewFixedPoint.toLocaleString() +
              ' + 알바비 1,000'
          }
          const historyType = 'fee'
          const amount = this.reviewFixedPoint
          const data = {
            content: content,
            status: 'confirm',
            historyType: historyType,
            amount: amount,
            article: this.thisArticle,
          }
          await this.$axios.post(url, data).then(res => {
            if (res && res.data && res.data.status === 'SUCCESS') {
              alert('포인트 처리가 완료되었습니다.')
              this.singleRefresh()
            } else {
              alert(res.data.message ? res.data.message : '오류가 발생하였습니다.')
            }
          }).catch(e => {
            alert('오류가 발생하였습니다.')
          })
        }
      },
      deleteArticle () {
        const url = 'article'
        this.$axios.delete(url, { data: this.thisArticle }).then(res => {
          if (res && res.data && res.data.status === 'SUCCESS') {
            this.$router.go(-1)
          } else {
            alert(res.data.message || '오류가 발생하였습니다.')
          }
        }).catch(() => {
          alert('오류가 발생하였습니다.')
        })
      },
      singleRefresh () {
        const url = `article/${this.article.idx}`
        this.$axios.get(url).then(res => {
          if (res && res.data) {
            this.$emit('refresh', this.article)
          }
        })
      },
      closePointProcess () {
        this.showProcessPoint = false
        this.showUpsideProcessPoint = false
        this.$emit('refresh')
      },
      async uploadImage (file) {
        if (file) {
          const formData = new FormData()
          formData.append('image', file)
          this.$axios.post(`/article/${this.article.idx}/attachment`, formData).then(res => {
            if (res && res.data && res.data.status === 'SUCCESS') {
              if (this.popup) {
                this.singleRefresh()
              } else {
                this.$emit('refresh')
              }
              this.image = null
            }
          })
        }
      },
    },
  }
</script>

<style scoped lang="scss">
  #article-content::v-deep {
    img {
      max-width: 100%;
    }
  }
  .stepper::v-deep {
    .v-stepper__step__step {
      max-width: initial;
      padding: 10px;
      width: initial;
      border-radius: 1rem;
    }
  }
</style>
